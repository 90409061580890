<template>
  <div>
    <headerNav activeIndex="3"></headerNav>
    <div class="designBody">
      <div class="designImg">
        <div style="padding-top:119px">创意设计服务</div>
        <div style="margin-top:32px;">
          <span style="width:620px;font-size: 40px;display:inline-block">从现在开始，用更富魅力的方法， 敲响客户的大门。</span>
        </div>
      </div>
      <div class="designText">
        <div class="title">服务介绍</div>
        <div class="spanText">
          <div style="margin-right:15px"><img src="../../assets/img/designText.png" style="width:12px;height:12px;"></div>
          <div>团队设计师来自互联网大厂，多年设计经验，熟悉互联网各类产品的设计流程，对产品规划，交互视觉方面有很成熟的经验。</div>
        </div>
        <div class="spanText">
          <div style="margin-right:15px"><img src="../../assets/img/designText.png" style="width:12px;height:12px;"></div>
          <div>设计环节前期包含调研、关键词定义、情绪版构建、设计语言提炼；中期是界面设计、规范文档、设计延展；后期有蓝湖插件标注切图，落地支持、项目维护等等。</div>
        </div>
      </div>
      <div class="advantages">
        <div class="title">服务优势</div>
        <div class="cardBody">
          <div class="cardBodyDiv">
              <div class="parkDiv">
                <div class="parkDivImg"></div>
              </div>
              <div class="parkTitle">全栈设计经验</div>
              <div class="parkSpan" style="margin-top: 6px">多端设计能力</div>
              <div class="parkSpan" >全品牌方案输出</div>
              <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
          <div class="cardBodyDiv">
            <div class="parkDiv">
              <div class="parkDivImg1"></div>
            </div>
            <div class="parkTitle">定制化设计</div>
            <div class="parkSpan" style="margin-top: 6px">私人定制</div>
            <div class="parkSpan" >满足更多个性需求</div>
            <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
          <div class="cardBodyDiv">
            <div class="parkDiv">
              <div class="parkDivImg2"></div>
            </div>
            <div class="parkTitle">1对1服务</div>
            <div class="parkSpan" style="margin-top: 6px">专业UX设计师</div>
            <div class="parkSpan" >一对一对接服务</div>
            <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
        </div>
      </div>
      <div class="successful">
        <div class="title">成功案例</div>
        <div class="cardBody">
          <div class="cardBodyDiv">
            <img src="../../assets/img/successful1.png"  style="border-radius:16px 16px 0px 0px"/>
            <div class="cardTitle">达美汽车供应链APP管理系统</div>
            <div class="span">一款管理汽车订单销售的app</div>
          </div>
          <div class="cardBodyDiv">
            <img src="../../assets/img/successful2.png"  style="border-radius:16px 16px 0px 0px"/>
            <div class="cardTitle">建投建材集团下的“经营管理平台”</div>
            <div class="span">帮助集团梳理业务数据</div>
          </div>
          <div class="cardBodyDiv">
            <img src="../../assets/img/successful3.png"  style="border-radius:16px 16px 0px 0px"/>
            <div class="cardTitle">费控报销APP财费系统</div>
            <div class="span">一款解决企业报销问题的app工具</div>
          </div>
          <div class="cardBodyDiv">
            <img src="../../assets/img/successful4.png"  style="border-radius:16px 16px 0px 0px"/>
            <div class="cardTitle">财务系统管理后台</div>
            <div class="span">管理财务订单和状态的后台系统</div>
          </div>
        </div>
      </div>
    </div>
    <contactNav :bj="true"></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form:{},
      rules: {

      }
    };
  },
  methods: {

  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.designBody{
  width:100%;
  min-height: 800px;
  .designImg{
    width:100%;
    height:500px;
    background:url("../../assets/img/designImg.png") no-repeat center;
    background-size: 100% 100%;
  }
  .designImg div{
    width:1200px;
    margin:0 auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 80px;
    color: #FFFFFF;
    letter-spacing: 2px;
    text-align: left;
    font-style: normal;
  }
  .designText{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
    }
    .spanText{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #393939;
      line-height: 36px;
      text-align: left;
      font-style: normal;
      margin-top: 30px;
      display: flex;
    }
  }
  .advantages{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      margin-bottom: 20px;
    }
    .cardBody{
      width:1200px;
      height: 372px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cardBodyDiv{
        width: 374px;
        height: 372px;
        background: #E6F3FF;
        border-radius: 8px;
        cursor:pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all  .25s ;
        .parkDiv{
          width: 128px;
          height: 128px;
          background: #FFFFFF;
          border-radius: 100px;
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          .parkDivImg{
            width: 54px;
            height: 54px;
            background: url("../../assets/img/parkDiv1.svg") no-repeat center;
            background-size: 100% 100%;
          }
          .parkDivImg1{
            width: 54px;
            height: 54px;
            background: url("../../assets/img/parkDiv3.svg") no-repeat center;
            background-size: 100% 100%;
          }
          .parkDivImg2{
            width: 54px;
            height: 54px;
            background: url("../../assets/img/parkDiv5.svg") no-repeat center;
            background-size: 100% 100%;
          }
        }
        .parkTitle{
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 28px;
          color: #050829;
          line-height: 40px;
          text-align: left;
          font-style: normal;
          margin-top: 10px;
        }
        .parkSpan{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #050829;
          line-height: 24px;
          text-align: center;
          font-style: normal;
        }
        .parkBtn{
          width: 120px;
          height: 40px;
          background: transparent;
          border-radius: 4px;
          border: 1px solid #4D6EFD;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #4D6EFD;
          line-height: 40px;
          text-align: center;
          font-style: normal;
          margin-top: 20px;
        }
      }
      .cardBodyDiv:hover{
        background: linear-gradient( 180deg, #5E8FFA 0%, #175FF5 99%);
        border-radius: 8px;
        .parkDiv{
          .parkDivImg{
            width: 54px;
            height: 54px;
            background: url("../../assets/img/parkDiv2.svg") no-repeat center;
            background-size: 100% 100%;
          }
          .parkDivImg1{
            width: 54px;
            height: 54px;
            background: url("../../assets/img/parkDiv4.svg") no-repeat center;
            background-size: 100% 100%;
          }
          .parkDivImg2{
            width: 54px;
            height: 54px;
            background: url("../../assets/img/parkDiv6.svg") no-repeat center;
            background-size: 100% 100%;
          }
        }
        .parkTitle{
          color: #FFFFFF;
        }
        .parkSpan{
          color: #FFFFFF;
        }
        .parkBtn{
          border: 1px solid #FFFFFF;
          border-radius: 4px;
          color: #FFFFFF;
        }
      }
    }
  }
  .successful{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      margin-bottom: 30px;
    }
    .cardBody{
      width:1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .cardBodyDiv{
        flex: 1;
        width: 560px;
        min-width:560px;
        max-width:560px;
        height: 481px;
        border-radius: 16px;
        margin-bottom: 30px;
        cursor:pointer;
        .cardTitle{
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 28px;
          color: #050829;
          text-align: left;
          font-style: normal;
          padding-left: 30px;
          padding-top: 30px;
        }
        .span{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #484848;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          padding-left: 30px;
          padding-top: 10px;
        }
      }
      .cardBodyDiv:hover{
        background: #FFFFFF;
        box-shadow: 0px 0px 50px 0px rgba(198,218,230,0.66);
      }

    }

  }
}
</style>
