<template>
  <div>
    <headerNav activeIndex="3"></headerNav>
    <div class="designBody">
      <div class="designImg">
        <div style="padding-top:119px">创意设计服务</div>
        <div style="margin-top:32px;">
          <span style="width:620px;font-size: 40px;display:inline-block">从现在开始，用更富魅力的方法， 敲响客户的大门。</span>
        </div>
      </div>
      <div class="designText">
        <div class="title">服务介绍</div>
        <div class="spanText">
          <div style="margin-right:15px"><img src="../../assets/img/designText.png" style="width:12px;height:12px;"></div>
          <div>深入挖掘用户需求，灵活设计策略，以体验驱动的方式，创造出打动人心的数字体验。</div>
        </div>
      </div>
      <div class="advantages">
        <div class="title">服务流程</div>
        <div class="cardBody">
          <div class="flow">
            <div class="flowDiv">
              <img src="../../assets/img/flow1.png" style="width:99px;height:99px;">
            </div>
            <div>
              <div style="margin-top: 20px;font-size: 24px;">需求梳理分析</div>
              <div style="margin-top:13px">充分的市场调研与需求分析， 推导产品方向</div>
            </div>
          </div>
          <div class="flowXian"></div>
          <div class="flow">
            <div class="flowDiv">
              <img src="../../assets/img/flow2.png" style="width:99px;height:99px;">
            </div>
            <div>
              <div style="margin-top: 20px;font-size: 24px;">产品设计把控</div>
              <div style="margin-top:13px">根据商业模式与产品定位， 定制产品方案</div>
            </div>
          </div>
          <div class="flowXian"></div>
          <div class="flow">
            <div class="flowDiv">
              <img src="../../assets/img/flow3.png" style="width:99px;height:99px;">
            </div>
            <div>
              <div style="margin-top: 20px;font-size: 24px;">项目维护优化</div>
              <div style="margin-top:13px">后期跟进项目发展，维护与迭代优化</div>
            </div>
          </div>
        </div>
      </div>
      <div class="successful">
        <div class="title">成功案例</div>
        <div class="cardBody">
           <div class="cardBodyDiv">
             <div><img src="../../assets/img/hhxt.jpg" style="width:595px;height:374px"></div>
             <div>
               <div style="font-size: 24px;margin-bottom: 10px;margin-top:10px">汉河系统</div>
               <div>数智化中台赋能，人财物事尽在掌握</div>
             </div>
           </div>
          <div class="cardBodyDiv">
            <div><img src="../../assets/img/jggl.jpg" style="width:595px;height:374px"></div>
            <div>
              <div style="font-size: 24px;margin-bottom: 10px;margin-top:10px">建工经营管理系统</div>
              <div>传统系统数字化、智能化</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contactNav :bj="true"></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form:{},
      rules: {

      }
    };
  },
  methods: {

  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.designBody{
  width:100%;
  min-height: 800px;
  .designImg{
    width:100%;
    height:500px;
    background:url("../../assets/img/designImg.png") no-repeat center;
    background-size: 100% 100%;
  }
  .designImg div{
    width:1200px;
    margin:0 auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 80px;
    color: #FFFFFF;
    letter-spacing: 2px;
    text-align: left;
    font-style: normal;
  }
  .designText{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
    }
    .spanText{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #393939;
      line-height: 36px;
      text-align: left;
      font-style: normal;
      margin-top: 30px;
      display: flex;
    }
  }
  .advantages{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      margin-bottom: 30px;
    }
    .cardBody{
      width:1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .flowXian{
        width: 220px;
        border: 1px dashed #A6B3BC;
        position: relative;
        top: -45px;
      }
      .flow{
        width:220px;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #050829;
        line-height: 24px;
        font-style: normal;
        .flowDiv{
          width:220px;
          height: 220px;
          background: #3773FE;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor:pointer;
          transition: all  .2s  ease-in;
        }
        .flowDiv:hover{
          transform:translateY(-10px);
        }

      }
    }
  }
  .successful{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      margin-bottom: 10px;
    }
    .cardBody{
      width:1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #050829;
      text-align: center;
      font-style: normal;
      margin-bottom: 30px;
      cursor:pointer;
      transition: all  .2s  ease-in;
      .cardBodyDiv{
        padding-bottom: 20px;
        border-radius: 16px;
      }
      .cardBodyDiv:hover{
        background: #FFFFFF;
        box-shadow: 0px 0px 50px 0px rgba(198,218,230,0.66);
      }
    }

  }
}
</style>
