<template>
  <div>
    <headerNav activeIndex="3"></headerNav>
    <div class="designBody">
      <div class="designImg">
        <div style="padding-top:119px">创意设计服务</div>
        <div style="margin-top:32px;">
          <span style="width:620px;font-size: 40px;display:inline-block">从现在开始，用更富魅力的方法， 敲响客户的大门。</span>
        </div>
      </div>
      <div class="advantages">
        <div class="title">服务内容</div>
        <div class="cardBody">
           <div class="service">
             <div class="serviceText">
               <div class="textTitle">系统功能测试</div>
               <div>通过对目标系统需求分解形成测试用例，验证目标系统实现与需求的一致性和完整性、界面的合理性和兼容性， 并输出系统功能测试报告。</div>
               <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
             </div>
             <div class="serviceImg">
               <img src="../../assets/img/service1.png" style="width:124px;height:124px;">
             </div>
           </div>
          <div class="service">
            <div class="serviceImg">
              <img src="../../assets/img/service2.png" style="width:124px;height:124px;">
            </div>
            <div class="serviceText" style="text-align: right">
              <div class="textTitle" style="text-align: right">系统功能测试</div>
              <div>通过对目标系统需求分解形成测试用例，验证目标系统实现与需求的一致性和完整性、界面的合理性和兼容性， 并输出系统功能测试报告。</div>
              <div style="display:flex;justify-content: flex-end"> <router-link to="/contact" ><div class="parkBtn">与我们合作</div></router-link></div>
            </div>
          </div>
          <div class="service">
            <div class="serviceText">
              <div class="textTitle">系统功能测试</div>
              <div>通过对目标系统需求分解形成测试用例，验证目标系统实现与需求的一致性和完整性、界面的合理性和兼容性， 并输出系统功能测试报告。</div>
              <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
            </div>
            <div class="serviceImg">
              <img src="../../assets/img/service3.png"  style="width:124px;height:124px;">
            </div>
          </div>
        </div>
      </div>
      <div class="successful">
        <div class="title">成功案例</div>
        <div class="cardBody">
           <div class="case"><img src="../../assets/img/case1.jpg"  style="width:383px;height:277px;"></div>
           <div class="case"><img src="../../assets/img/case2.jpg"  style="width:383px;height:277px;"></div>
           <div class="case"><img src="../../assets/img/case3.jpg"  style="width:383px;height:277px;"></div>
        </div>
      </div>
    </div>
    <contactNav :bj="true"></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form:{},
      rules: {

      }
    };
  },
  methods: {

  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.designBody{
  width:100%;
  min-height: 800px;
  .designImg{
    width:100%;
    height:500px;
    background:url("../../assets/img/designImg.png") no-repeat center;
    background-size: 100% 100%;
  }
  .designImg div{
    width:1200px;
    margin:0 auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 80px;
    color: #FFFFFF;
    letter-spacing: 2px;
    text-align: left;
    font-style: normal;
  }
  .designText{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
    }
    .spanText{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #393939;
      line-height: 36px;
      text-align: left;
      font-style: normal;
      margin-top: 30px;
      display: flex;
    }
  }
  .advantages{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      margin-bottom: 30px;
    }
    .cardBody{
      width:1200px;
      .service:hover{
        background: linear-gradient( 180deg, #5E8FFA 0%, #175FF5 99%);
        border-radius: 8px;
        cursor:pointer;
       .serviceText{
          color: #FFFFFF;
          .textTitle{
            color: #FFFFFF;
          }
         .parkBtn{
           border: 1px solid #FFFFFF;
           border-radius: 4px;
           color: #FFFFFF;
         }
        }
      }
      .service{
        cursor:pointer;
        width: 1140px;
        height: 148px;
        background: #E6F3FF;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 30px;
        .serviceText{
          width:760px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #050829;
          line-height: 24px;
          text-align: left;
          font-style: normal;
          .textTitle{
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 500;
            font-size: 24px;
            color: #050829;
            line-height: 35px;
            text-align: left;
            font-style: normal;
            margin-bottom: 20px;
          }
          .parkBtn{
            width: 120px;
            height: 40px;
            background: transparent;
            border-radius: 4px;
            border: 1px solid #4D6EFD;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15px;
            color: #4D6EFD;
            line-height: 40px;
            text-align: center;
            font-style: normal;
            margin-top: 20px;
          }
        }
      }
    }
  }
  .successful{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      margin-bottom: 30px;
    }
    .cardBody{
      width:1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #050829;
      line-height: 21px;
      text-align: center;
      font-style: normal;
      margin-bottom: 50px;
       .case{
         width:383px;
         height:277px;
         cursor:pointer;
       }
      .case:hover{
        cursor:pointer;
        transition: all 0.3s ;
        transform: scale(1.05);
      }
    }

  }
}
</style>
