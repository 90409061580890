<template>
  <div>
    <div class="container" id="container" @scroll="handleScroll">
      <headerNav activeIndex="4"></headerNav>
      <div class="aboutUsBody">
        <div class="aboutUsImg"></div>
        <div class="aboutUsNav">
          <div class="aboutUsNavTitle">
            <div v-for="(item,index) in navList" :key="index" :class="navIndex == index ? 'navSelected':'navDiv'" @click="navClick(index)">
              {{item}}
            </div>
          </div>
        </div>
        <div class="profile">
          <div id="section-0" style="position:relative;top:-150px;"></div>
          <div class="aboutUsTitle">公司简介</div>
          <div class="aboutUsText"></div>
          <div class="profileSpan"><span style="font-size:22px;font-weight:600">湖南链动云科技有限公司</span>是大汉集团旗下的数字科技公司，前身为大汉电子商务有限公司（简称大汉电商）的数字化中心，成立于2014年。一直负责大汉电商的大大买钢平台及其数字化系列产品的打造，推动了大汉钢铁版块业务模式的不断变化、业务规模逐年提升。除服务大汉电商外，湖南链动云科技有限公司也承接了大汉集团汽车版块、大汉集团财费业务、其余大汉体系外公司的数字化项目，有多年成功的B端电子交易平台、企业数字化咨询、数字化产品与研发经验和能力。</div>
        </div>
        <div class="cooperation">
          <img src="../../assets/img/cooperation.png" style="width:1200px;height:153px"/>
        </div>
        <div class="development">
          <div id="section-1" style="position:relative;top:-150px;"></div>
          <div class="aboutUsTitle" style="color:#FFFFFF;padding-top:126px">发展历程</div>
          <div class="aboutUsText"  style="background: #FFFFFF;"></div>
          <div class="developmentImg"></div>
        </div>
        <div class="technology">
          <div id="section-2" style="position:relative;top:-150px;"></div>
          <div class="aboutUsTitle">科技成果</div>
          <div class="aboutUsText" style="margin-bottom:10px"></div>
          <div class="technologyDiv">
            <div class="technologyBody">
              <div class="technologyImg"><img src="../../assets/img/technologyImg1.png" /></div>
              <div class="technologyText">链动云标准版平台软著证书</div>
            </div>
            <div class="technologyBody">
              <div class="technologyImg"><img src="../../assets/img/technologyImg2.png" /></div>
              <div class="technologyText">湖南链动云科技有限公司链动云专属版平台软著证书</div>
            </div>
            <div class="technologyBody">
              <div class="technologyImg"><img src="../../assets/img/technologyImg3.png" /></div>
              <div class="technologyText">链云票系统软著证书</div>
            </div>
            <div class="technologyBody">
              <div class="technologyImg"><img src="../../assets/img/technologyImg4.png" /></div>
              <div class="technologyText">链捷报系统软著证书</div>
            </div>
            <div class="technologyBody">
              <div class="technologyImg"><img src="../../assets/img/technologyImg5.png" /></div>
              <div class="technologyText">链动云小程序软件软著证书</div>
            </div>
            <div class="technologyBody">
              <div class="technologyImg"><img src="../../assets/img/technologyImg6.jpg" /></div>
              <div class="technologyText">链财会财务管理系统软著证书</div>
            </div>
          </div>
        </div>
        <div class="enterprises">
          <div id="section-3" style="position:relative;top:-150px;"></div>
          <div class="aboutUsTitle" style="padding-top: 50px;">企业文化</div>
          <div class="aboutUsText"></div>
          <div class="enterprisesDiv">
            <div><img src="../../assets/img/enterprises1.png" style="width:377px;height:450px"/></div>
            <div><img src="../../assets/img/enterprises2.png" style="width:377px;height:450px"/></div>
            <div><img src="../../assets/img/enterprises3.png" style="width:377px;height:450px"/></div>
          </div>
        </div>
        <div class="brand">
          <div id="section-4" style="position:relative;top:-150px;"></div>
          <div class="aboutUsTitle">品牌墙</div>
          <div class="aboutUsText"></div>
          <div class="brandImg">
            <div><img src="../../assets/img/brandImg1.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg2.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg3.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg4.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg5.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg6.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg7.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg8.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg9.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg0.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg11.jpg"  style="width:100%;height:100%"/></div>
            <div><img src="../../assets/img/brandImg12.jpg"  style="width:100%;height:100%"/></div>
          </div>
        </div>
        <contactNav></contactNav>
      </div>
      <footerNav></footerNav>
      <el-backtop  target=".container"  :bottom="80"></el-backtop>
    </div>
  </div>
</template>
<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'aboutUs',
  components: {
    headerNav,
    contactNav,
    footerNav
  },
  data() {
    return {
        navIndex:0,
        navList:['公司简介','发展历程','科技成果','企业文化','品牌墙']
    };
  },
  methods: {
    handleScroll(event) {
      if(event.target.scrollTop < 800){
        this.navIndex = 0
      }else if(event.target.scrollTop > 800 && event.target.scrollTop < 1850 ){
        this.navIndex = 1
      }else if(event.target.scrollTop > 1850 && event.target.scrollTop < 3100 ){
        this.navIndex = 2
      }else if(event.target.scrollTop > 3100 && event.target.scrollTop < 3800 ){
        this.navIndex = 3
      }else if(event.target.scrollTop > 3800){
        this.navIndex = 4
      }
    },
    navClick(index){
       //this.navIndex = index;
      const element = document.getElementById('section-' + index);
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    },
  }
};
</script>
<style lang='scss' scoped>
.container{
  width: 100vw;
  height: 100vh;
  overflow-y:scroll;
  position: relative;
  .aboutUsBody{
    width:100%;
    .aboutUsImg{
      width:100%;
      height: 427px;
      background:url("../../assets/img/aboutUsImg.png") no-repeat center;

    }
    .aboutUsNav{
      width:100%;
      height: 60px;
      background: #F3F6FF;
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 88px; /* 定义元素距离视窗顶部的距离 */
      z-index: 998;
      .aboutUsNavTitle{
        width: 1200px;
        margin:0px auto;
        display: flex;
        cursor:pointer;
        user-select: none;
        .navDiv{
          width: 240px;
          height: 60px;
          font-family: PingFangSC, PingFang SC;
          font-size: 18px;
          color: #050829;
          line-height: 60px;
          text-align: center;
          font-style: normal;
        }
        .navSelected{
          width: 240px;
          height: 60px;
          font-family: PingFangSC, PingFang SC;
          font-size: 18px;
          color: #4177FB;
          line-height: 60px;
          text-align: center;
          font-style: normal;
          background: #e5edff;
          border-bottom: 3px solid #4177FB;
        }
      }
    }
    .aboutUsTitle{
      padding-top:50px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 30px;
      color: #050829;
      line-height: 56px;
      letter-spacing: 1px;
      text-align: center;
      font-style: normal;
    }
    .aboutUsText{
      width: 62px;
      height: 2px;
      background: #4177FB;
      margin: 22px auto;
      margin-top: 10px;
    }
    .profile{
      width:1200px;
      margin:0px auto;

      .profileSpan{
        margin-top:30px;
        font-family: PingFangSC, PingFang SC;
        font-size: 18px;
        color: #050829;
        line-height: 36px;
        text-align: left;
        font-style: normal;
      }
    }
    .cooperation{
      margin-top:-27px;
      position: relative;
      top: 77px;
      height: 153px;
      width:100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .development{
      width:100%;
      margin:0px auto;
      height: 1052px;
      background: #263A5E;
      .developmentImg{
        width:1200px;
        height: 744px;
        background:url("../../assets/img/developmentImg.png") no-repeat center;
        background-size: 100% 100%;
        margin: 0 auto;
        margin-top: 30px;
      }
    }
    .technology{
      width:1200px;
      margin:0px auto;
      padding-bottom: 50px;
      .technologyDiv{
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .technologyBody{
          margin: 20px auto;
          flex: 1;
          min-width: 377px;
          max-width:377px;
          height: 486px;
          background: #FFFFFF;
          box-shadow: 0px 0px 30px 0px rgba(2,47,142,0.1);
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .technologyImg{
            width:240px;
            height:324px;
            cursor:pointer;
            transition: all  .3s  ease-in;
            img{
              width:100%;
              height:100%;
            }
          }
          .technologyImg:hover {
            transform: scale(1.2);
          }
          .technologyText{
            margin-top: 35px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #050829;
            line-height: 25px;
            text-align: center;
            font-style: normal;
          }
        }
      }
    }
    .enterprises{
      width:100%;
      margin:0px auto;
      height: 691px;
      background:url("../../assets/img/enterprisesImg.png") no-repeat center;
      background-size: 100% 100%;
      .enterprisesDiv{
        width: 1200px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        img:hover{
          cursor:pointer;
          transition: all 0.2s;
          transform: scale(1.05);
        }
        div{
          flex: 1;
          min-width: 377px;
          max-width:377px;
          height: 450px;
        }
      }
    }
    .brand{
      width:1200px;
      margin:0px auto;
      height: 662px;
      .brandImg{
        margin:0px auto;
        margin-top: 30px;
        width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        div{
          flex: 1;
          min-width:280px;
          max-width:280px;
          height: 117px;
          cursor:pointer;
          transition: all  .3s  ease-in;
          margin-bottom: 30px;
        }
        div:hover {
          transform: scale(1.15);
        }
      }
    }
  }
}

</style>
