<template>
  <div>
    <headerNav activeIndex="3"></headerNav>
    <div class="designBody">
      <div class="designImg">
        <div style="padding-top:119px">创意设计服务</div>
        <div style="margin-top:32px;">
          <span style="width:620px;font-size: 40px;display:inline-block">从现在开始，用更富魅力的方法， 敲响客户的大门。</span>
        </div>
      </div>
      <div class="designText">
        <div class="title">服务介绍</div>
        <div class="spanText">
          <div style="margin-right:15px"><img src="../../assets/img/designText.png" style="width:12px;height:12px;"></div>
          <div>将需求转化为实际运行的软件系统,网站建设、小程序开发、APP开发、系统开发。</div>
        </div>
      </div>
      <div class="advantages">
        <div class="title">服务优势</div>
        <div class="cardBody">
          <div class="cardBodyDiv">
            <div class="parkDiv">
              <div class="parkDivImg"></div>
            </div>
            <div class="parkTitle">优化用户体验</div>
            <div class="parkSpan" style="margin-top: 6px">前端技术的优化，</div>
            <div class="parkSpan" >用户能够流畅体验产品。</div>
            <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
          <div class="cardBodyDiv">
            <div class="parkDiv">
              <div class="parkDivImg1"></div>
            </div>
            <div class="parkTitle">跨平台兼容性</div>
            <div class="parkSpan" style="margin-top: 6px">uniapp深度优化，使得Web端应用也能获得</div>

            <div class="parkSpan" >接近原生应用的体验 。</div>
            <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
          <div class="cardBodyDiv">
            <div class="parkDiv">
              <div class="parkDivImg2"></div>
            </div>
            <div class="parkTitle">响应式设计</div>
            <div class="parkSpan" style="margin-top: 6px">实现了在不同移动设备上的良好展示</div>
            <div class="parkSpan" >效果和用户体验。</div>
            <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
          <div class="cardBodyDiv">
            <div class="parkDiv">
              <div class="parkDivImg3"></div>
            </div>
            <div class="parkTitle">丰富的技术栈和SEO优化</div>
            <div class="parkSpan" style="margin-top: 6px">通过AngularUniversal技术，</div>
            <div class="parkSpan" >提高了网站在搜索引擎中的排名和曝光度。</div>
            <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
          <div class="cardBodyDiv">
            <div class="parkDiv">
              <div class="parkDivImg4"></div>
            </div>
            <div class="parkTitle">代码质量和可维护性</div>
            <div class="parkSpan" style="margin-top: 6px">高效地维护和扩展项目代码，</div>
            <div class="parkSpan" >降低了出错率和维护成本。</div>
            <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
          <div class="cardBodyDiv">
            <div class="parkDiv">
              <div class="parkDivImg5"></div>
            </div>
            <div class="parkTitle">更多其他</div>
            <div class="parkSpan" style="margin-top: 6px">定制化开发…</div>
            <div class="parkSpan"  style="height:24px"></div>
            <router-link to="/contact"><div class="parkBtn">与我们合作</div></router-link>
          </div>
        </div>
      </div>
      <div class="successful">
        <div class="title">成功案例</div>
        <div class="cardBody">
          <div class="cardBodyDiv">
            <div><img src="../../assets/img/ddmg.jpg"  style="width:566px;height:317px"></div>
            <div>
              <div style="font-size: 24px;margin-bottom: 10px;margin-top:20px">跨平台电商应用</div>
              <div>数智化中台赋能，人财物事尽在掌握</div>
            </div>
          </div>
          <div class="cardBodyDiv">
            <div><img src="../../assets/img/hcy.jpg" style="width:566px;height:317px"></div>
            <div>
              <div style="font-size: 24px;margin-bottom: 10px;margin-top:20px">跨平台电商应用</div>
              <div>传统系统数字化、智能化</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contactNav :bj="true"></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form:{},
      rules: {

      }
    };
  },
  methods: {

  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.designBody{
  width:100%;
  min-height: 800px;
  .designImg{
    width:100%;
    height:500px;
    background:url("../../assets/img/designImg.png") no-repeat center;
    background-size: 100% 100%;
  }
  .designImg div{
    width:1200px;
    margin:0 auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 80px;
    color: #FFFFFF;
    letter-spacing: 2px;
    text-align: left;
    font-style: normal;
  }
  .designText{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
    }
    .spanText{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #393939;
      line-height: 36px;
      text-align: left;
      font-style: normal;
      margin-top: 30px;
      display: flex;
    }
  }
  .advantages{
    width:1200px;
    margin:0 auto;
    margin-top: 50px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      margin-bottom: 30px;
    }
    .cardBody{
      width:1200px;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cardBodyDiv{
        min-width: 374px;
        max-width: 374px;
        height: 372px;
        background: #E6F3FF;
        border-radius: 8px;
        cursor:pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all  .25s ;
        margin-bottom: 40px;
        .parkDiv{
          width: 128px;
          height: 128px;
          background: #FFFFFF;
          border-radius: 100px;
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          .parkDivImg{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg1.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg1{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg2.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg2{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg3.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg3{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg4.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg4{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg5.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg5{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg6.png") no-repeat center;
            background-size: 50px 50px;
          }
        }
        .parkTitle{
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 28px;
          color: #050829;
          line-height: 40px;
          text-align: left;
          font-style: normal;
          margin-top: 10px;
        }
        .parkSpan{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #050829;
          line-height: 24px;
          text-align: center;
          font-style: normal;
        }
        .parkBtn{
          width: 120px;
          height: 40px;
          background: transparent;
          border-radius: 4px;
          border: 1px solid #4D6EFD;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #4D6EFD;
          line-height: 40px;
          text-align: center;
          font-style: normal;
          margin-top: 20px;
        }
      }
      .cardBodyDiv:hover{
        background: linear-gradient( 180deg, #5E8FFA 0%, #175FF5 99%);
        border-radius: 8px;
        .parkDiv{
          .parkDivImg{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg7.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg1{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg8.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg2{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg9.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg3{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg10.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg4{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg11.png") no-repeat center;
            background-size: 50px 50px;
          }
          .parkDivImg5{
            width: 50px;
            height: 50px;
            background: url("../../assets/img/parkDivImg12.png") no-repeat center;
            background-size: 50px 50px;
          }
        }
        .parkTitle{
          color: #FFFFFF;
        }
        .parkSpan{
          color: #FFFFFF;
        }
        .parkBtn{
          border: 1px solid #FFFFFF;
          border-radius: 4px;
          color: #FFFFFF;
        }
      }
    }
  }
  .successful{
    width:1200px;
    margin:0 auto;
    margin-top: 10px;
    .title{
      margin: 0 auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      font-size: 30px;
      color: #050829;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      margin-bottom: 10px;
    }
    .cardBody{
      width:1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #050829;
      text-align: center;
      font-style: normal;
      margin-bottom: 30px;
      cursor:pointer;
      transition: all  .2s  ease-in;
      .cardBodyDiv{
        padding-bottom: 20px;
        border-radius: 16px;
      }
      .cardBodyDiv:hover{
        background: #FFFFFF;
        box-shadow: 0px 0px 50px 0px rgba(198,218,230,0.66);
      }
    }

  }
}
</style>
