<template>
  <div>
    <headerNav activeIndex="2" productsIndex="1"></headerNav>
    <div class="productsBody">
      <div class="productsImg">
        <div>
          <router-link to="/contact"><el-button type="primary" class="formBtn" >免费咨询</el-button></router-link>
        </div>
      </div>
      <div class="titleImg">
         <img src="../../assets/img/ldyTitle1.png" style="width:257px;height:42px"/>
      </div>
      <div class="productsCard">
        <div class="cardDiv">
          <img src="../../assets/img/cardImg2.png"/>
          <div class="cardTitle">数字化沉淀</div>
          <div>丰富的大宗商品供应链</div>
          <div>数字化经验沉淀</div>
        </div>
        <div class="cardDiv">
          <img src="../../assets/img/cardImg3.png"/>
          <div class="cardTitle">经营理念提升</div>
          <div>不仅仅是数字工具,</div>
          <div>更是经营管理理念提升</div>
        </div>
        <div class="cardDiv">
          <img src="../../assets/img/cardImg4.png"/>
          <div class="cardTitle">免安装试用</div>
          <div>免安装可云上试用,</div>
          <div>可独立部署</div>
        </div>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle2.png" style="width:257px;height:42px"/>
      </div>
      <div class="structure">
        <img src="../../assets/img/structureImg2.png"  style="width:1020px;height:788px;padding:50px 0px"/>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle3.png"  style="width:257px;height:42px"/>
      </div>
      <div class="case">
        <img src="../../assets/img/caseImg.png" />
      </div>
    </div>
    <contactNav></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form:{},
      rules: {

      }
    };
  },
  methods: {

  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.productsBody{
  width:100%;
  min-height: 800px;
  .productsImg{
    width:100%;
    height:562px;
    background:url("../../assets/img/productsBg.jpg") no-repeat center;
    background-size:1920px 562px;
    div{
      width: 1200px;
      margin: 0 auto;
      .formBtn{
        width: 176px;
        height: 50px;
        background: #4C6DFD;
        border-radius: 8px;
        position: relative;
        top: 375px;
        border: none;
      }
      .formBtn:hover{
        background: #7D93F5;
        border-radius: 8px;
      }
    }
  }
  .titleImg{
    width:1200px;
    margin:30px auto;
    margin-top: 50px;
    text-align: center;
  }
  .productsCard{
    width:1200px;
    height:347px;
    margin:0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cardDiv{
      width:377px;
      height:349px;
      background:url("../../assets/img/cardImg.png") no-repeat center;
      cursor:pointer;
      transition: all  .2s  ease-in;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #393939;
      line-height: 24px;
      text-align: center;
      font-style: normal;
      .cardTitle{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #050829;
        line-height: 40px;
        text-align: center;
        font-style: normal;
        margin-top: 48px;
        margin-bottom: 20px;
      }
    }
    .cardDiv:hover{
      transform: scale(1.05);
    }
  }
  .structure{
    width:100%;
    background: #F0F9FF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .case{
    width:1200px;
    margin:0px auto;
    margin-bottom: 100px;
  }

}
</style>
