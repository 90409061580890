var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container",attrs:{"id":"container"},on:{"scroll":_vm.handleScroll}},[_c('headerNav',{attrs:{"activeIndex":"4"}}),_c('div',{staticClass:"aboutUsBody"},[_c('div',{staticClass:"aboutUsImg"}),_c('div',{staticClass:"aboutUsNav"},[_c('div',{staticClass:"aboutUsNavTitle"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,class:_vm.navIndex == index ? 'navSelected':'navDiv',on:{"click":function($event){return _vm.navClick(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('contactNav')],1),_c('footerNav'),_c('el-backtop',{attrs:{"target":".container","bottom":80}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile"},[_c('div',{staticStyle:{"position":"relative","top":"-150px"},attrs:{"id":"section-0"}}),_c('div',{staticClass:"aboutUsTitle"},[_vm._v("公司简介")]),_c('div',{staticClass:"aboutUsText"}),_c('div',{staticClass:"profileSpan"},[_c('span',{staticStyle:{"font-size":"22px","font-weight":"600"}},[_vm._v("湖南链动云科技有限公司")]),_vm._v("是大汉集团旗下的数字科技公司，前身为大汉电子商务有限公司（简称大汉电商）的数字化中心，成立于2014年。一直负责大汉电商的大大买钢平台及其数字化系列产品的打造，推动了大汉钢铁版块业务模式的不断变化、业务规模逐年提升。除服务大汉电商外，湖南链动云科技有限公司也承接了大汉集团汽车版块、大汉集团财费业务、其余大汉体系外公司的数字化项目，有多年成功的B端电子交易平台、企业数字化咨询、数字化产品与研发经验和能力。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cooperation"},[_c('img',{staticStyle:{"width":"1200px","height":"153px"},attrs:{"src":require("../../assets/img/cooperation.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"development"},[_c('div',{staticStyle:{"position":"relative","top":"-150px"},attrs:{"id":"section-1"}}),_c('div',{staticClass:"aboutUsTitle",staticStyle:{"color":"#FFFFFF","padding-top":"126px"}},[_vm._v("发展历程")]),_c('div',{staticClass:"aboutUsText",staticStyle:{"background":"#FFFFFF"}}),_c('div',{staticClass:"developmentImg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"technology"},[_c('div',{staticStyle:{"position":"relative","top":"-150px"},attrs:{"id":"section-2"}}),_c('div',{staticClass:"aboutUsTitle"},[_vm._v("科技成果")]),_c('div',{staticClass:"aboutUsText",staticStyle:{"margin-bottom":"10px"}}),_c('div',{staticClass:"technologyDiv"},[_c('div',{staticClass:"technologyBody"},[_c('div',{staticClass:"technologyImg"},[_c('img',{attrs:{"src":require("../../assets/img/technologyImg1.png")}})]),_c('div',{staticClass:"technologyText"},[_vm._v("链动云标准版平台软著证书")])]),_c('div',{staticClass:"technologyBody"},[_c('div',{staticClass:"technologyImg"},[_c('img',{attrs:{"src":require("../../assets/img/technologyImg2.png")}})]),_c('div',{staticClass:"technologyText"},[_vm._v("湖南链动云科技有限公司链动云专属版平台软著证书")])]),_c('div',{staticClass:"technologyBody"},[_c('div',{staticClass:"technologyImg"},[_c('img',{attrs:{"src":require("../../assets/img/technologyImg3.png")}})]),_c('div',{staticClass:"technologyText"},[_vm._v("链云票系统软著证书")])]),_c('div',{staticClass:"technologyBody"},[_c('div',{staticClass:"technologyImg"},[_c('img',{attrs:{"src":require("../../assets/img/technologyImg4.png")}})]),_c('div',{staticClass:"technologyText"},[_vm._v("链捷报系统软著证书")])]),_c('div',{staticClass:"technologyBody"},[_c('div',{staticClass:"technologyImg"},[_c('img',{attrs:{"src":require("../../assets/img/technologyImg5.png")}})]),_c('div',{staticClass:"technologyText"},[_vm._v("链动云小程序软件软著证书")])]),_c('div',{staticClass:"technologyBody"},[_c('div',{staticClass:"technologyImg"},[_c('img',{attrs:{"src":require("../../assets/img/technologyImg6.jpg")}})]),_c('div',{staticClass:"technologyText"},[_vm._v("链财会财务管理系统软著证书")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enterprises"},[_c('div',{staticStyle:{"position":"relative","top":"-150px"},attrs:{"id":"section-3"}}),_c('div',{staticClass:"aboutUsTitle",staticStyle:{"padding-top":"50px"}},[_vm._v("企业文化")]),_c('div',{staticClass:"aboutUsText"}),_c('div',{staticClass:"enterprisesDiv"},[_c('div',[_c('img',{staticStyle:{"width":"377px","height":"450px"},attrs:{"src":require("../../assets/img/enterprises1.png")}})]),_c('div',[_c('img',{staticStyle:{"width":"377px","height":"450px"},attrs:{"src":require("../../assets/img/enterprises2.png")}})]),_c('div',[_c('img',{staticStyle:{"width":"377px","height":"450px"},attrs:{"src":require("../../assets/img/enterprises3.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand"},[_c('div',{staticStyle:{"position":"relative","top":"-150px"},attrs:{"id":"section-4"}}),_c('div',{staticClass:"aboutUsTitle"},[_vm._v("品牌墙")]),_c('div',{staticClass:"aboutUsText"}),_c('div',{staticClass:"brandImg"},[_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg1.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg2.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg3.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg4.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg5.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg6.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg7.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg8.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg9.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg0.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg11.jpg")}})]),_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../assets/img/brandImg12.jpg")}})])])])
}]

export { render, staticRenderFns }