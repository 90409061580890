<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  底部栏目
-->
<template>
  <div
      class="join"
      :class="{ 'joinHover': hover }"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
  >
      <div class="joinStyle">
          <div class="joinText">共聚 · 共创 · 共赢</div>
          <div class="joinTitle">构建生态合作 共享发展机遇</div>
          <div class="joinSpan">全国招募合作伙伴，与链动云科技一起共创价值，构建事业共同体</div>
          <el-button class="btn" @click="handleSelect">立即加入</el-button>
      </div>
  </div>
</template>

<script>
export default {
  name: "join",
  data() {
    return {
      hover: false
    };
  },
  methods: {
    handleSelect(){
      this.$router.push({
        name: "joinPage",
      });
    },

  },
  computed: {},
};
</script>
<style lang="scss" >
.join{
  width: 100%;
  height: 389px;
  background: url("../assets/img/join.png")no-repeat center;
  background-size: cover; /* 背景图片覆盖整个div */
  transition: background 0.5s ease; /* 动画过渡效果 */
  cursor:pointer;
  .joinStyle{
    width: 800px;
    text-align: center;
    margin: 0 auto;
    .joinText{
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
      font-style: normal;
      padding-top: 80px;
    }
    .joinTitle{
      margin-top: 20px ;
      margin-bottom: 10px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 40px;
      color: #FFFFFF;
      line-height: 56px;
      letter-spacing: 1px;
      text-align: center;
      font-style: normal;
    }
    .joinSpan{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 21px;
      text-align: center;
      font-style: normal;
    }
    .btn{
      width: 180px;
      height: 52px;
      margin-top: 20px ;
      background: #FFFFFF;
      border-radius: 8px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #396CFB;
      border: none;
    }
    .btn:hover{
      background: #396CFB;
      color: #FFFFFF;
      border: none;
    }
  }
}
.joinHover{
  background: url("../assets/img/join2.png")no-repeat center;
  background-size: cover; /* 背景图片覆盖整个div */
  cursor:pointer;
}
</style>
