<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  底部栏目
-->
<template>
  <div>
    <div class="bj" style="height:200px; margin-bottom:80px;text-align: center;" v-if="bj">
      <img src="../assets/img/bj.jpg" style="width:1200px;height:200px;margin:0 auto;cursor:pointer;"  @click="handleSelect">
    </div>
    <div class="contact">
      <div style="padding-top:50px;margin-bottom:30px">量身定制属于您的解决方案</div>
      <el-button class="btn" type="text" @click="handleSelect">联系我们</el-button>
    </div>
  </div>

</template>

<script>
export default {
  name: "contact",
  data() {
    return {};
  },
  props:{
    bj:{
      type: "boolean",
      default: false
    }
  },
  methods: {
    handleSelect(){
      this.$router.push({
        name: "contact",
      });
    },
  },
};
</script>
<style lang="scss" >

  .contact{
    width: 100%;
    height: 220px;
    background: url("../assets/img/contact.png")no-repeat center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #FFFFFF;
    line-height: 40px;
    letter-spacing: 1px;
    font-style: normal;
    text-align: center;
    .btn{
      width: 176px;
      height: 50px;
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 22px;
      font-style: normal;
      background: transparent;
    }
    .btn:hover{
      background: #FFFFFF;
      border-radius: 4px;
      color: #4C6DFD;
    }
  }

</style>
