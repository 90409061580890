<template>
  <div>
    <headerNav activeIndex="2" productsIndex="2"></headerNav>
    <div class="productsBody">
      <div class="productsImg">
        <div>
          <router-link to="/contact"><el-button type="primary" class="formBtn" >免费咨询</el-button></router-link>
        </div>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle1.png" style="width:257px;height:42px"/>
      </div>
      <div class="advantages">
        <div class="advantagesDiv">
          <img src="../../assets/img/lianyunpiao1.png" />
          <div class="advantagesTitle">全票种、多场景兼容</div>
          <div class="advantagesContent">专/普票，数电票、全电票、机动车票等多种票 种支持，跨行业多场景开票无忧</div>
        </div>
        <div class="advantagesDiv">
          <img src="../../assets/img/lianyunpiao2.png" />
          <div class="advantagesTitle">开放平台、无惧对接</div>
          <div class="advantagesContent">支持嵌入自有业务系统开课，手工直接开票， 开放平台，无惧对接</div>
        </div>
        <div class="advantagesDiv">
          <img src="../../assets/img/lianyunpiao3.png" />
          <div class="advantagesTitle">发票生命周期管理</div>
          <div class="advantagesContent">发票申领，分发、开票、作废、冲红直至抄 报税的全生命周期管理</div>
        </div>
        <div class="advantagesDiv">
          <img src="../../assets/img/lianyunpiao4.png" />
          <div class="advantagesTitle">高效智能、信息无忧</div>
          <div class="advantagesContent">对接天眼查等工具，自动获取企业信息，提高客户信息准确率</div>
        </div>
        <div class="advantagesDiv">
          <img src="../../assets/img/lianyunpiao5.png" />
          <div class="advantagesTitle">一键批量灵活开票</div>
          <div class="advantagesContent">自定义拆分合并，商品融合等开票参数，自动 匹配，一键批量灵活开票</div>
        </div>
        <div class="advantagesDiv" style="display: flex;justify-content: center;align-items: center;font-size: 18px;color: #999999;">
          <div style="width:100px;text-align: center">
            <img src="../../assets/img/lianyunpiao6.png" />
            <div style="margin-top:10px">敬请期待</div>
          </div>
        </div>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle2.png" style="width:257px;height:42px"/>
      </div>
      <div class="structure">
        <img src="../../assets/img/structureImg3.png"  style="width:1000px;height:434px;padding:50px 0px"/>
      </div>
      <div class="titleImg">
        <img src="../../assets/img/ldyTitle3.png" style="width:257px;height:42px"/>
      </div>
      <div class="case">
        <img src="../../assets/img/caseImg1.svg" />
      </div>
    </div>
    <contactNav></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import contactNav from "@/components/contactNav.vue";
import {mapState, mapActions} from "vuex";

export default {
  name: 'contact',
  components: {
    headerNav,
    footerNav,
    contactNav
  },
  data() {
    return {
      form: {},
      rules: {}
    };
  },
  methods: {},
  computed: {},
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.productsBody {
  width: 100%;
  min-height: 800px;

  .productsImg {
    width: 100%;
    height: 562px;
    background: url("../../assets/img/productsBg1.jpg") no-repeat center;
    background-size:1920px 562px;
    div{
      width: 1200px;
      margin: 0 auto;
      .formBtn{
        width: 176px;
        height: 50px;
        background: #4C6DFD;
        border-radius: 8px;
        position: relative;
        top: 375px;
        border: none;
      }
      .formBtn:hover{
        background: #7D93F5;
        border-radius: 8px;
      }
    }
  }
  .titleImg{
    width:1200px;
    margin:30px auto;
    margin-top: 50px;
    text-align: center;
  }
  .advantages{
    width:1200px;
    margin:0px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .advantagesDiv{
      width: 330px;
      min-width: 330px;
      max-width: 330px;
      height: 246px;
      background: #FFFFFF;
      box-shadow: 0px 0px 40px 0px rgba(220,235,241,0.75);
      border-radius: 16px;
      margin: 10px auto;
      padding-top: 31px;
      padding-left: 30px;
      padding-right: 20px;
      cursor:pointer;
      transition: all  .2s  ease-in;
      .advantagesTitle{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #050829;
        line-height: 40px;
        font-style: normal;
        margin-top: 17px;
      }
      .advantagesContent{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #050829;
        line-height: 30px;
        text-align: left;
        font-style: normal;
        margin-top: 17px;
      }
    }
    .advantagesDiv:hover{
      transform: scale(1.04);
    }
  }
  .structure{
    width:100%;
    background: #F0F9FF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .case{
    width:1200px;
    margin:0px auto;
    margin-bottom: 100px;
  }

}
</style>
