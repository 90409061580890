var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headerNav',{attrs:{"activeIndex":"2","productsIndex":"1"}}),_c('div',{staticClass:"productsBody"},[_c('div',{staticClass:"productsImg"},[_c('div',[_c('router-link',{attrs:{"to":"/contact"}},[_c('el-button',{staticClass:"formBtn",attrs:{"type":"primary"}},[_vm._v("免费咨询")])],1)],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('contactNav'),_c('footerNav')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{staticStyle:{"width":"257px","height":"42px"},attrs:{"src":require("../../assets/img/ldyTitle1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productsCard"},[_c('div',{staticClass:"cardDiv"},[_c('img',{attrs:{"src":require("../../assets/img/cardImg2.png")}}),_c('div',{staticClass:"cardTitle"},[_vm._v("数字化沉淀")]),_c('div',[_vm._v("丰富的大宗商品供应链")]),_c('div',[_vm._v("数字化经验沉淀")])]),_c('div',{staticClass:"cardDiv"},[_c('img',{attrs:{"src":require("../../assets/img/cardImg3.png")}}),_c('div',{staticClass:"cardTitle"},[_vm._v("经营理念提升")]),_c('div',[_vm._v("不仅仅是数字工具,")]),_c('div',[_vm._v("更是经营管理理念提升")])]),_c('div',{staticClass:"cardDiv"},[_c('img',{attrs:{"src":require("../../assets/img/cardImg4.png")}}),_c('div',{staticClass:"cardTitle"},[_vm._v("免安装试用")]),_c('div',[_vm._v("免安装可云上试用,")]),_c('div',[_vm._v("可独立部署")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{staticStyle:{"width":"257px","height":"42px"},attrs:{"src":require("../../assets/img/ldyTitle2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"structure"},[_c('img',{staticStyle:{"width":"1020px","height":"788px","padding":"50px 0px"},attrs:{"src":require("../../assets/img/structureImg2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleImg"},[_c('img',{staticStyle:{"width":"257px","height":"42px"},attrs:{"src":require("../../assets/img/ldyTitle3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case"},[_c('img',{attrs:{"src":require("../../assets/img/caseImg.png")}})])
}]

export { render, staticRenderFns }