<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  底部栏目
-->
<template>
  <div class="footer">
      <div class="footerTitle">
        <div class="title">专注供应链领域数字化转型陪伴者</div>
        <div class="nav">
          <router-link to="/index"><div>网站首页</div></router-link>
          <router-link to="/liandongyun"><div>产品超市</div></router-link>
          <router-link to="/other"><div>咨询定制</div></router-link>
          <router-link to="/aboutUs/index"><div>公司简介</div></router-link>
          <router-link to="/contact"><div>联系我们</div></router-link>
        </div>
      </div>
      <div class="footerDiv">
        <div class="left">
          <div class="leftTitle">联系我们</div>
          <div class="leftPhone"><img src="../assets/img/phone.png" style="margin-right:8px">180-7313-1810</div>
          <div class="leftText">地址: 湖南省长沙市望城区金桥国际未来城3栋2层</div>
          <div class="leftEwm">
            <img src="../assets/img/ewm.jpg" style="width:100px;height:100px">
            <div class="leftText" style="margin-left:20px;padding-top:15px">
              <div>扫一扫关注链动云</div>
              <div style="margin-top:10px">公众号，获取最新资讯</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="leftTitle">我们是谁</div>
          <div class="rightText">
            湖南链动云科技有限公司是大汉集团旗下的数字科技公司，前身为大汉电子商务有限公司
            (简称大汉电商）的数字化中心，成立于2014年。一直负责大汉电商的大大买钢平台及其数
            字化系列产品的打造，推动了大汉钢铁版块业务模式的不断变化、业务规模逐年提升。除服
            务大汉电商外，湖南链动云科技有限公司也承接了大汉集团汽车版块、大汉集团财费业务、其余大汉体系外公
            司的数字化项目，有多年成功的B端电子交易平台、企业数字化咨询、数字化产品与研发经
            验和能力。
          </div>
        </div>
      </div>
      <div class="footerText">
        <div style="width:560px;">湖南链动云科技有限公司版权所有</div>
        <div style="display:flex;align-items:center;justify-content:start;width:560px;">
          <div>
            湘ICP备2024049751号-1
          </div>

          <div style="display:flex;align-items:center;margin-left:20px">
            <img  src="../assets/img/bah.png" style="width:20px;height:20px;"/>
            <a style="color: #ffffff" href="https://beian.mps.gov.cn/#/query/webSearch?code=43019002002472" rel="noreferrer" target="_blank">湘公网安备43019002002472号</a>
          </div>

        </div>

      </div>
  </div>
</template>

<script>
export default {
  name: "footerNav",
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<style lang="scss" >
   .footer{
     width: 100%;
     height: 554px;
     background: #000000;
     text-align: center;
     .footerTitle{
       margin: 0 auto;
       width: 1200px;
       height: 118px;
       display: flex;
       align-items: center;
       justify-content: space-between;
       border-bottom: 1px solid #3B3333;
       .title{
         font-family: PingFangSC, PingFang SC;
         font-weight: 500;
         font-size: 28px;
         color: #FFFFFF;
         line-height: 40px;
         text-align: left;
         font-style: normal;
       }
       .nav{
         width: 560px;
         height: 22px;
         display: flex;
         justify-content: space-between;
         align-items: center;
         font-family: PingFangSC, PingFang SC;
         font-weight: 500;
         font-size: 16px;
         color: #7F7F7F;
         line-height: 22px;
         font-style: normal;
         cursor:pointer;
       }
       .nav div{
         color: #7F7F7F;
       }
       .nav div:hover{
         color: #FFFFFF;
       }
     }
     .footerDiv{
       width: 1200px;
       height: 350px;
       border-bottom: 1px solid #3B3333;
       margin: 0 auto;
       display: flex;
       justify-content: space-between;
       .leftTitle{
         width: 560px;
         height:90px;
         font-family: PingFangSC, PingFang SC;
         font-weight: 500;
         font-size: 28px;
         color: #FFFFFF;
         line-height: 110px;
         text-align: left;
         font-style: normal;
         border-bottom: 1px solid #3B3333;
       }
       .left{
         width: 560px;
         .leftPhone{
           font-family: PingFangSC, PingFang SC;
           font-weight: 500;
           font-size: 28px;
           color: #2AA5FE;
           text-align: left;
           font-style: normal;
           margin-top: 20px;
           margin-bottom: 10px;
         }
         .leftText{
           font-family: PingFangSC, PingFang SC;
           font-weight: 400;
           font-size: 14px;
           color: #FFFFFF;
           line-height: 20px;
           text-align: left;
           font-style: normal;
           margin-top: 10px;
           opacity: 0.5;
         }
         .leftEwm{
           margin-top: 30px;
           display: flex;
           img:hover{
             cursor:pointer;
             transition: all 0.2s ease-in;
             transform: scale(1.6);
           }
         }
       }
       .right{
         width: 560px;
         .rightText{
           margin-top: 20px;
           font-family: PingFangSC, PingFang SC;
           font-weight: 400;
           font-size: 14px;
           color: #FFFFFF;
           line-height: 24px;
           text-align: left;
           font-style: normal;
           opacity: 0.5;
         }
       }
     }
     .footerText{
       width: 1200px;
       height: 80px;
       margin: 0 auto;
       display: flex;
       align-items: center;
       justify-content: space-between;
       font-family: PingFangSC, PingFang SC;
       font-weight: 400;
       font-size: 14px;
       color: #FFFFFF;
       line-height: 20px;
       text-align: left;
       opacity: 0.5;
       font-style: normal;
     }
   }
</style>
